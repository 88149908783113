.slide__content {
 	width: var(--width);
	max-width: 1280px;
    margin: auto;
    overflow: hidden;
    //border-radius: 5px;
    div {
        // height: 400px;
        max-height: 250px;
        img {
            object-fit: cover;
            height: 100%;
        }
    }
}