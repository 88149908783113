.driver__container {
	width: var(--full-width);
	// 	max-width: var(--max-width);
	// 	margin: auto;

	// * HERO CONTAINER STYLES
	.hero__container {
		// padding: 0 1rem;
		.hero__img {
			img {
				width: 100%;
			}
		}

		.hero__content {
			width: 100%;
			background-color: var(--red-color-hex);
			border-radius: 15% 0 0 0;
			text-align: center;
			h1 {
				font-style: normal;
				font-weight: 900;
				font-size: 34px;
				padding-right: 5px;
				font-family: "Inter-Bold";
				padding-top: 1.7rem;
				color: #fff;
			}

			.hero__para1 {
				color: #fff;
				font-style: normal;
				font-weight: 900;
				font-size: 20px;
				padding-right: 5px;
				font-family: "Inter-light";
				margin-top: 1rem;
				padding-right: 5px;
				padding-left: 5px;
			}

			.hero__download {
				display: flex;
				gap: 2rem;
				justify-content: center;
				padding: 1.5rem 0;
				flex-wrap: wrap;
				img {
					cursor: pointer;
					width: 100%;
					max-width: 300px;
				}
			}
		}

		.hero__paragraph-text {
			padding-bottom: 1.7rem;
			p {
				color: #fff;
				font-style: normal;
				font-weight: 900;
				font-size: 20px;
				padding-right: 5px;
				font-family: "Inter-light";
				margin-top: 1rem;
			}
		}
	}

	// * ====DRIVER CARD INFO STYLES====

	.driver__card-info-wrapper {
		display: grid;
		gap: 2em;
		width: 95%;
		margin: 4rem auto;
		max-width: fit-content;
		
		@media screen and (max-width: 772px) {
			grid-template-columns: repeat(1, 1fr);
		}
	
		@media screen and (min-width: 772px) {
			max-width: 100%;
			grid-template-columns: repeat(2, 1fr);
			max-width: fit-content;
		}
		.single__card-info {
			width: 360px;
			height: 100%;
			background-color: #fbf4f4;
			border-radius: 1em;
	
			@media screen and (max-width: 400px) {
				width: 100%;
			}
			.card__img {
				margin-top: 2rem;
				display: flex;
				justify-content: center;
				img {
					width: 120px;
				}
			}
			.card__info-text {
				margin: 1rem 2rem;
				text-align: center;
	
				h4 {
					font-style: normal;
					font-weight: 900;
					font-size: 22px;
					padding-right: 5px;
					font-family: "Inter-Bold";
					padding-bottom: 1rem;
					color: #000;
				}
				p {
					font-style: normal;
					font-weight: 900;
					font-size: 16px;
					padding-right: 5px;
					font-family: "Inter-Light";
					padding-bottom: 1rem;
					color: #000;
				}
			}
		}
	}

	// * ====END OF DRIVER CARD INFO STYLES====

	// * ====STEPS TO FOLLOW STYLES====
	.driver__steps-container {
		margin-top: 3rem;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			43.28deg,
			#9a1403 17.81%,
			rgb(243, 65, 65) 85.69%
		);
		// clip-path: polygon(0 0, 100% 0, 100% 92%, 0% 100%);
	}
	// * ==== END OF STEPS TO FOLLOW STYLES====
	// * ====QUESTIONs STYLES====
	.question__img {
		position: relative;
		width: 95%;
		max-width: 1500px;
		margin: 2rem auto;
		img {
			border-radius: 30px;
			z-index: -1;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.question__title {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 2rem;

			h3 {
				font-family: "Inter-Light";
				font-style: normal;
				font-weight: 700;
				font-size: clamp(2rem, 2.5vw, 6rem);
				line-height: 130.62%;
				color: #fff;
			}
		}

		.questions__container {
			z-index: 3;

			.questions__info {
				border-radius: var(--radius);
				padding: 2.5rem 2rem;
			}
		}
	}

	// * ====END OF QUESTIONs STYLES====
}
.header__shrink {
	position: sticky;
right: 0;
	top: 0;
	left: 0;
	background: #fff;
	z-index: 99999;
	// box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
	box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.15);

}