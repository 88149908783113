.corporatebanner__container {
  width: var(--width);
  max-width: 1280px;
  margin: 47px auto 106px auto;
  position: relative;
  z-index: -1;

  height: 500px;
  overflow: hidden;
  border-radius: 20px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
