.main__page-container {
	width: 100%;
	height: 100%;
	background-image: url("../assets/images/bg_image.png");
	background-size: cover;
	background-attachment: fixed;
	z-index: -1;
	overflow: hidden;
	.main__page-content {
		// * SLIDE AND ABOUT STYLES

		// *ABOUT STYLES
		.about__content {
			margin-top: 150px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 150px;

			@media screen and (max-width: 1006px) {
				flex-direction: column;
				align-items: center;
				gap: 40px;
				margin-top: 20px;
			}
			.text__content {
				p {
					font-family: "Inter-Light";
					font-style: normal;
					font-weight: 500;
					font-size: 24px;
					line-height: 140.62%;
					color: #000000;
					width: 500px;

					@media screen and (max-width: 563px) {
						width: 100%;
					}
				}
			}
		}

		// * END OF SLIDE AND ABOUT STYLES



		// *ENGINEER STYLES
		.engineer__content {
			margin-top: 150px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 150px;

			@media screen and (max-width: 1006px) {
				flex-direction: column;
				align-items: center;
				gap: 40px;
				margin-top: 20px;
			}
			.text__content {
				p {
					font-family: "Inter-Light";
					font-style: normal;
					font-weight: 500;
					font-size: 24px;
					line-height: 140.62%;
					color: #000000;
					width: 500px;

					@media screen and (max-width: 563px) {
						width: 100%;
					}
				}
			}
		}

// END OF ENGINEER STYLING


		// ! CAR STYLING
		.car__image {
			position: absolute;
			z-index: 2;

			@media screen and (max-width: 1200px) {
				display: none;
			}
		}
		.car1 {
			bottom: 170px;
			left: 720px;
		}
		.car2 {
			bottom: 90px;
			left: 910px;
		}
		.car3 {
			bottom: 0px;
			left: 1150px;
		}

		// ! SOCIAL IMPACT STYLES

		.social__content {
			margin-top: 150px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 150px;
			margin-bottom: 5rem;
			@media screen and (max-width: 1006px) {
				flex-direction: column-reverse;
				align-items: center;
				gap: 40px;
				margin-top: 60px;
			}

			.social__text-content {
				p {
					font-family: "Inter-Light";
					font-style: normal;
					font-weight: 500;
					font-size: 24px;
					line-height: 140.62%;
					color: #000000;
					width: 500px;

					@media screen and (max-width: 563px) {
						width: 100%;
					}
				}
			}
		}
	}

	// * CAPTAIN STYLES

	.captain__container {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: url("../assets/images/Rectangle15.png");
		background-size: cover;
		background-attachment: fixed;
		.captain__wrap {
			margin: 70px 0;
			display: flex;
			justify-content: center;
			align-items: 0;
			padding: 3rem 0rem;
			gap: 40px;

			@media screen and (max-width: 1118px) {
				flex-direction: column-reverse;
				align-items: center;
				padding: 1rem;
			}
			.captain__img {
				@media screen and (max-width: 1118px) {
					padding-top: 2rem;
					padding-bottom: 1rem;
				}
			}
			.captain__text {
				margin-left: 4rem;
				margin-top: 2rem;
				@media screen and (max-width: 1118px) {
					margin-top: 0.5rem;
				}
			}

			p {
				font-family: "Inter-Light";
				font-style: normal;
				font-weight: 500;
				font-size: 24px;
				line-height: 140.62%;
				color: #fff;
				width: 500px;

				@media screen and (max-width: 563px) {
					width: 100%;
				}
			}
		}
	}
	// *OUR BLOG STYLES
	.our__blog {
		width: 100%;
		height: 100%;
		background-image: url("../assets/images/Rectangle15.png");
		background-size: cover;
		background-attachment: fixed;
		padding-top: 2rem;
		.our__blog-content {
			padding-top: 2rem;
			padding-bottom: 3rem;
			h3 {
				font-family: "Inter-Light";
				font-style: normal;
				font-weight: 700;
				font-size: 50px;
				line-height: 140.62%;
				color: #ffffff;

				@media screen and (max-width: 1293px) {
					text-align: center;
				}
			}

			
		}
	}
}
