.blog__cards {
				padding-top: 2rem;
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
				grid-row-gap: 3rem;
				grid-column-gap: 1rem;
				// margin-bottom: rem;
				@media screen and (max-width: 1293px) {
					place-items: center;
				}

				.single__card-blog {
					img {
						@media screen and (max-width: 400px) {
							width: 300px;
						}
					}
					.blog__para1 {
						font-family: "Inter-Light";
						font-style: normal;
						font-weight: 700;
						font-size: 20px;
						line-height: 140.62%;
						color: #ffffff;
						width: 354px;
						margin-top: 1rem;
						@media screen and (max-width: 400px) {
							width: 300px;
						}
					}

					.blog__para2 {
						font-family: "Inter";
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 140.62%;
						color: #000000;
						width: 354px;
						margin: 1rem 0 1rem 0;
						@media screen and (max-width: 400px) {
							width: 300px;
						}
					}
				}
			}