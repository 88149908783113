@tailwind base;
@tailwind components;
@tailwind utilities;

// ====== CSS VARIABLES======
:root {
	--red-color-hex: #ff1e00;
	--red-color-rgb: rgba(255, 30, 0, 255);
	--light-red: #ff5050;
	--clr-red-special: #b4345c;
	--black-color: #000;
	--transition: all 0.3s linear;
	--clr-white: #fff;
	--dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	--radius: 0.25rem;
	--fixed-width: 620px;
	--clr-grey: hsl(210, 22%, 49%);
	--width: 95%;
	--max-width: 1300px;
	--light-blue: rgb(218, 237, 238);
	--full-width: 100%;
	--clr-grey-special: #eae6eb;
	--light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	--transition: all 0.3s linear;
	--clr-primary-5: hsl(205, 78%, 60%);
}

body {
	background: #fff;
}
*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

ul {
	list-style-type: none;
}
a {
	text-decoration: none;
	color: #000;
}

button {
	border: 0;
	outline: none;
}

img {
	object-fit: cover;
}


.link {
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 17px;
	color: var(--black-color);
	padding-right: 5px;
	font-family: "Inter-Light";
	margin-bottom: 0.7rem;
	margin-top: 0.9rem;
}

.sub__links {
	a {
		display: block;
		margin: 0.4rem 4rem;
		// width: fit-content;
		padding: 0.5rem;
	}
}

.heading__text {
	font-family: "Inter-Light";
	font-style: normal;
	font-weight: 700;
	// font-size: 50px;
	font-size: clamp(1.5rem, 2.5vw, 6rem);

	line-height: 130.62%;
	color: #000000;
}

.red__btn {
	font-family: "Inter-Light";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 140.62%;
	color: #ffffff;
	// width: 135px;
	padding: 0 1rem 0 1rem;
	height: 40px;
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.2)
		),
		#ff0000;
	border-radius: 5px;
}

.white__btn {
	font-family: "Inter-Light";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 140.62%;
	color: #000;
	// width: 100%;
	padding: 0 1rem 0 1rem;

	height: 40px;
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.2)
		),
		#ffffff;
	border-radius: 5px;
}

.white__text {
	font-family: "Inter-Light";
	font-style: normal;
	font-weight: 700;
	// font-size: 50px;
	font-size: clamp(1.5rem, 2.5vw, 6rem);
	// line-height: 140.62%;
	color: #ffffff;
}

.center__item {
	width: var(--width);
	max-width: 1280px;
	margin: 1rem auto;
}

.see__more-btn {
	font-family: "Inter-Light";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 140.62%;
	color: rgb(243, 65, 65);
}

.business__form-container {
	width: 95%;
	max-width: 600px;
	margin: auto;
	.business__form-wrap {
		.form__container {
			input[type="text"],
			textarea {
				color: #2d2e2e !important;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border-radius: 0.3em;
				border: 1px solid #cecece;
				box-sizing: border-box;
				background-color: transparent;
				font-size: 0.9em;
				font-family: "Inter-Light";
				padding: 1.1rem 0.75rem;
				display: block;
				width: 100%;
				font-weight: 600;
				line-height: 1.2em;
				margin: 0;
				outline: 2px solid transparent !important;
				outline-offset: 2px !important;
				background: rgba(0, 0, 0, 0.06);

				&::placeholder {
					color: rgba(0, 0, 0, 0.2);
					font-size: 1rem;
					font-weight: 700;
				}
				&:focus {
					border: 1px solid var(--light-red);
				}
			}
		}
	}
}

.driver__card-info-container {
	display: grid;
	gap: 2em;
	width: 95%;
	margin: 4rem auto;
	max-width: fit-content;
	@media screen and (max-width: 1136px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (max-width: 772px) {
		grid-template-columns: repeat(1, 1fr);
	}

	@media screen and (min-width: 1136px) {
		max-width: 100%;
		grid-template-columns: repeat(3, 1fr);
		max-width: fit-content;
	}
	.single__card-info {
		width: 360px;
		height: 100%;
		background-color: #fbf4f4;
		border-radius: 1em;

		@media screen and (max-width: 400px) {
			width: 100%;
		}
		.card__img {
			margin-top: 2rem;
			display: flex;
			justify-content: center;
			img {
				width: 120px;
			}
		}
		.card__info-text {
			margin: 1rem 2rem;
			text-align: center;

			h4 {
				font-style: normal;
				font-weight: 900;
				font-size: 22px;
				padding-right: 5px;
				font-family: "Inter-Bold";
				padding-bottom: 1rem;
				color: #000;
			}
			p {
				font-style: normal;
				font-weight: 900;
				font-size: 16px;
				padding-right: 5px;
				font-family: "Inter-Light";
				padding-bottom: 1rem;
				color: #000;
			}
		}
	}
}

// ! EXPLORE MORE CONTAINER STYLES

.exploreMore__card-info-container {
	display: grid;
	gap: 2em;
	margin: 4rem auto;
	width: 95%;
	max-width: fit-content;

	@media screen and (max-width: 1136px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (max-width: 772px) {
		grid-template-columns: repeat(1, 1fr);
	}

	@media screen and (min-width: 1136px) {
		max-width: 100%;
		grid-template-columns: repeat(3, 1fr);
		max-width: fit-content;
	}

	.single__card-info {
		width: 360px;
		height: 100%;
		background-color: #fbf4f4;
		border-radius: 1em;
		@media screen and (max-width: 400px) {
			width: 100%;
		}
		.card__img {
			margin-top: 2rem;
			display: flex;
			justify-content: center;
			img {
				width: 120px;
			}
		}
		.card__info-text {
			margin: 1rem 2rem;
			text-align: center;

			h4 {
				font-style: normal;
				font-weight: 900;
				font-size: 22px;
				padding-right: 5px;
				font-family: "Inter-Bold";
				padding-bottom: 1rem;
				color: #000;
			}
			p {
				font-style: normal;
				font-weight: 900;
				font-size: 16px;
				padding-right: 5px;
				font-family: "Inter-Light";
				padding-bottom: 1rem;
				color: #000;
			}
		}
	}
}
