
@font-face {
	font-family: "Inter-Light"; /*Can be any text*/
	src: local("Inter-Light"),
		url("./assets/font/Inter-Light.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Regular"; /*Can be any text*/
	src: local("Inter-Regular"),
		url("./assets/font/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Black"; /*Can be any text*/
	src: local("Inter-Black"),
		url("./assets/font/Inter-Black.ttf") format("truetype");
}
@font-face {
	font-family: "Inter-Bold"; /*Can be any text*/
	src: local("Inter-Bold"),
		url("./assets/font/Inter-Bold.ttf") format("truetype");
}
