.go__wrap-container {
	width: 95%;
	max-width: 900px;
	margin: auto;

	h2 {
		font-style: normal;
		font-weight: 900;
		font-size: 5rem;
		padding-right: 5px;
		font-family: "Inter-Bold";
		text-align: center;
		padding-bottom: 1rem;
		color: #000;
		// padding-top: 3rem;
	}
}

.go__btn-container {
	margin-bottom: 4rem;
	display: flex;
	justify-content: center;
	gap: 20px;
    flex-wrap: wrap;

	.filter__btn {
		background: transparent;
		border-color: transparent;
		font-size: 1.2rem;
		text-transform: capitalize;
		margin: 0 0.5rem;
		letter-spacing: 1px;
		padding: 0.375rem 0.75rem;
		color: #000;
		cursor: pointer;
		transition: var(--transition);
		border-radius: var(--radius);

		&:hover {
			background: var(--light-red);
			color: var(--clr-white);
		}
	}

	.active__btn {
		background: var(--light-red);
		color: var(--clr-white);
	}
}

.single__item {

	p {
		font-style: normal;
		font-weight: 600;
		font-size: 1rem;
		padding-right: 5px;
		font-family: "Inter-Light";
		text-align: center;
		padding-bottom: 1rem;
		color: #000;
		width: 100%;
	}

	.item__img-content {
		display: flex;
		gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
		padding-top: 1.5rem;
		.img__flex {
			border-radius: 10px;
			width: 150px;
			height: 150px;
			background: #f4f8fb;
			padding: 1rem 0; 
            img {
				object-fit: cover;
			}
		}
	}
}
