.questions__wrap {
	padding: 1rem 1.5rem;
	margin-bottom: 1rem;
	border-radius: var(--radius);
	height: 100%;
	transition: var(--transition);

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		h4 {
			text-transform: none;
			line-height: 1.5;
			margin-bottom: 0;
			font-family: "Inter-Light";
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			color: #fff;
		}

		.btn__wrap {
			background: transparent;
			border-color: transparent;
			width: 2rem;
			height: 2rem;
			background: var(--clr-grey-special);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			color: var(--clr-red-special);
			cursor: pointer;
			margin-left: 1rem;
			align-self: center;
			min-width: 2rem;
		}
	}

	p {
		margin-top: 0.5rem;
		font-family: "Inter-Light";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		color: #fff;
		width: 100%;
		max-width: 900px;
	}
}

// * ====QUESTIONs STYLES====
	.question__img {
		position: relative;
		width: 95%;
		max-width: 1300px;
		margin: 3rem auto;

		img {
			border-radius: 30px;
			z-index: -1;
			width: 100%;
			height: 100%;
		}
		.question__title {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 2rem;

			h3 {
				font-family: "Inter-Light";
				font-style: normal;
				font-weight: 700;
				font-size: clamp(2rem, 2.5vw, 6rem);
				line-height: 130.62%;
				color: #fff;
			}
		}

		.questions__container {
			z-index: 3;

			.questions__info {
				border-radius: var(--radius);
				padding: 2.5rem 2rem;
			}
		}
	}

	// * ====END OF QUESTIONs STYLES====
